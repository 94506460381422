/* src/components/ContactForm.css */

.contact-form {
    width: 40%;
    margin-bottom: 10%;
    padding: 20px;
    background-color: var(--prim);
    border-radius: 8px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    margin-bottom: 5px;
    color: black;
}

.form-group input, .form-group textarea {
    width: 95%;
    padding: 10px;
    border: 1px solid var(--prim);
    border-radius: 4px;
    background-color: #fff;
}

.form-group textarea {
    height: 100px;
    resize: vertical;
}

.submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: var(--seco);
    color: var(--back);
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: var(--back);
    color: var(--seco);
}
